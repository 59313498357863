.calendar-container {
  padding: 30px;
  background-color: #f5f1e6; /* beige claro */
  min-height: 100vh;
  font-family: 'Segoe UI', sans-serif;
}

h2 {
  color: #333;
  margin-bottom: 20px;
}

.calendar-controls {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.calendar-controls button {
  background-color: #d8caa5;
  border: none;
  border-radius: 12px;
  color: #2c2c2c;
  font-weight: bold;
  padding: 10px 20px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: background-color 0.2s ease;
}

.calendar-controls button:hover {
  background-color: #cfc099;
}

.calendar-grid {
  display: grid;
  grid-template-columns: 100px repeat(6, 1fr);
  border: 1px solid #ddd;
  border-radius: 12px;
  overflow: hidden;
}

.calendar-header {
  background-color: #e0e0e0;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  color: #333;
}

.calendar-hour {
  background-color: #f0f0f0;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  color: #555;
}

.calendar-cell {
  border: 1px solid #eee;
  background-color: #fff;
  min-height: 60px;
  padding: 6px;
  cursor: pointer;
  transition: background 0.2s ease;
}

.calendar-cell:hover {
  background-color: #f3f3f3;
}

.clase-info {
  background-color: #f9f9f9;
  border-left: 4px solid #5f5f5f;
  padding: 6px;
  border-radius: 4px;
  font-size: 14px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal {
  background: #fffaf3;
  border: 1px solid #ddd;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  width: 400px;
  font-family: 'Arial', sans-serif;
  z-index: 1000;
}

.modal h3 {
  font-size: 20px;
  margin-bottom: 15px;
  color: #333;
}

.modal-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 12px;
  font-size: 14px;
  box-sizing: border-box;
}

.input-cupos {
  width: 80px;
  display: inline-block;
  margin-bottom: 12px;
}

.participantes-area {
  width: 100%;
  height: 70px;
  resize: vertical;
  font-size: 14px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
  margin-bottom: 12px;
}

.modal label {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #444;
}

.modal input[type="checkbox"] {
  margin-right: 8px;
}

.modal button {
  padding: 10px 16px;
  margin-right: 10px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
}

.modal button:first-of-type {
  background-color: #d6c7a1;
  color: #2c2c2c;
}

.modal button:nth-of-type(2) {
  background-color: #e8e8e8;
  color: #333;
}

.modal button:nth-of-type(3) {
  background-color: #ffdddd;
  color: #a00;
}
