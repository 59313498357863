kbody {
  margin: 0;
  font-family: 'Segoe UI', sans-serif;
  background-color: #f5f1e6; /* beige claro */
  color: #333;
}

/* --- LOGIN --- */
.login-container {
  max-width: 400px;
  margin: 80px auto;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.login-container h2 {
  text-align: center;
  margin-bottom: 25px;
  color: #5f5f5f;
}

.login-container input[type="email"],
.login-container input[type="password"] {
  width: 100%;
  padding: 12px;
  margin-bottom: 18px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 14px;
  background-color: #f9f9f9;
  box-sizing: border-box;
}

/* --- BOTON INGRESAR --- */

.login-container button {
  width: 100%;
  padding: 12px;
  background-color: #d8caa5;
  color: #2c2c2c;
  font-size: 15px;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  font-family: 'Segoe UI', sans-serif;
  cursor: pointer;
  box-shadow: 0px 2px 6px rgba(0,0,0,0.1);
  transition: background-color 0.2s ease;
}

.login-container button:hover {
  background-color: #cfc099;
}

/* --- BOTONES GENERALES --- */
button {
  background-color: #d8caa5;
  color: #2c2c2c;
  padding: 10px 18px;
  margin: 6px 4px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #c5b78e;
}

button:disabled {
  background-color: #eee;
  color: #aaa;
  cursor: not-allowed;
}

button.danger {
  background-color: #ffdddd;
  color: #a00;
}

button.danger:hover {
  background-color: #fbb;
}

/* --- MODAL CREAR/EDITAR CLASE --- */
.modal {
  background: #fffaf3;
  border: 1px solid #ddd;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  width: 400px;
  margin: 50px auto;
  font-family: 'Segoe UI', sans-serif;
}

.modal h3 {
  font-size: 20px;
  margin-bottom: 15px;
  color: #333;
}

.modal input[type="text"],
.modal input[type="number"],
.modal textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 12px;
  font-size: 14px;
  box-sizing: border-box;
}

.modal textarea {
  resize: vertical;
  height: 70px;
}

.modal label {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #444;
}

.modal input[type="checkbox"] {
  margin-right: 8px;
}

.navbar {
  background-color: #f5f1e6;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid #ddd;
}

.navbar a {
  background-color: #d9cbaa;
  padding: 10px 16px;
  border-radius: 14px;
  color: #1c1c1c;
  text-decoration: none;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  transition: background-color 0.2s ease;
}

.navbar a:hover {
  background-color: #c6bb9a;
  color: #000;
}
.alumno-panel {
  max-width: 700px;
  margin: 40px auto;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  color: #444;
}

.alumno-panel h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #5f5f5f;
  text-align: center;
}

.alumno-panel h3 {
  font-size: 18px;
  color: #5f5f5f;
  margin-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 5px;
}

.alumno-panel ul {
  list-style: none;
  padding: 0;
}

.alumno-panel li {
  background-color: #f9f8f4;
  padding: 12px 16px;
  margin-bottom: 8px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
}

.alumno-panel button {
  background-color: #5f5f5f;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  transition: background 0.2s ease;
}

.boton-uniforme {
  background-color: #4a4a4a;
  color: white;
  font-weight: bold;
  padding: 12px 24px;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0,0,0,0.1);
  transition: background-color 0.3s ease;
}

.boton-uniforme:hover {
  background-color: #333;
}
.boton-beige {
  background-color: #d8caa5;
  color: #2c2c2c;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  font-family: 'Segoe UI', sans-serif;
  cursor: pointer;
  box-shadow: 0px 2px 6px rgba(0,0,0,0.1);
  transition: background-color 0.2s ease;
}

.boton-beige:hover {
  background-color: #cfc099;
}
