.navbar {
  background-color: #f5f1e6;
  padding: 20px 0;
  display: flex;
  justify-content: center;  /* 👉 centrado horizontal */
  gap: 30px;
  border-bottom: 1px solid #ddd;
}

.navbar a {
  background-color: #d8caa5;
  padding: 12px 24px;
  border-radius: 16px;
  color: #2c2c2c;
  text-decoration: none;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  font-size: 18px;
  gap: 8px;
  box-shadow: 0px 4px 8px rgba(0,0,0,0.1);
  transition: background-color 0.3s ease;
}

.navbar a:hover {
  background-color: #cfc099;
}
.navbar {
  display: flex;
  justify-content: center; /* 👉 Esto centra los botones */
  gap: 20px; /* 👉 Espaciado entre ellos */
  padding: 20px;
  background-color: #f5f1e6;
  border-bottom: 1px solid #ddd;
}
