.students-container {
  padding: 30px;
  font-family: 'Segoe UI', sans-serif;
}

.students-container h2 {
  margin-bottom: 20px;
}

.students-container input[type="text"],
.students-container input[type="tel"] {
  padding: 8px;
  border-radius: 6px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.students-container table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
}

.students-container th,
.students-container td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.students-container th {
  background: #f9f9f9;
}

.students-container input[type="checkbox"] {
  transform: scale(1.2);
}

.students-container button {
  background: #00796b;
  color: white;
  padding: 8px 14px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
}

.students-container button:hover {
  background: #004d40;
}

.agregar-form {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
  align-items: center;
}
.students-container {
  padding: 1rem;
}
input[type="file"] {
  font-family: 'Segoe UI', sans-serif;
  font-size: 14px;
  border: none;
  background: none;
  color: #333;
}
input[type="file"]::file-selector-button {
  background-color: #d8caa5;
  color: #2c2c2c;
  font-weight: bold;
  padding: 10px 18px;
  border: none;
  border-radius: 12px;
  font-size: 15px;
  font-family: 'Segoe UI', sans-serif;
  cursor: pointer;
  box-shadow: 0px 2px 6px rgba(0,0,0,0.1);
  transition: background-color 0.2s ease;
}
input[type="file"]::file-selector-button:hover {
  background-color: #cfc099;
}
